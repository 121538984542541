<template>
  <v-list-item
    v-if="tweet"
    :class="{ 'p-tweetListItem--editing': editingTweet }"
    class="p-tweetListItem"
  >
    <v-list-item-avatar class="align-self-start p-tweetListItem__avatar">
      <Avatar :user="user" />
      <span class="c-feelingBadge">{{ _.get(tweet, 'feeling.emoji', defaultFeeling) }}</span>
    </v-list-item-avatar>
    <v-list-item-content class="flex-column align-start">
      <v-row
        align="center"
        class="ma-0 pa-0 flex-wrap mb-1"
      >
        <router-link
          v-if="tweet.user_id >0"
          :to="{ name: 'member/home', params: { id: tweet.user_id } }"
          class="p-tweetListItem__userName"
        >
          <strong>{{ userName }}</strong>
        </router-link>
        <strong v-else>{{ userName }}</strong>
        <div class="timeline-date ml-2">
          {{ $d($moment(tweet.created_at).toDate(), 'long') }}
        </div>
        <v-spacer />
      </v-row>
      <v-form
        v-show="editingTweet"
        class="p-tweetListItem__tweet"
        @submit.stop.prevent="save"
      >
        <MessageEditor
          v-validate="'required|max:280'"
          :attachments="editingTweet && editingTweet.attachments"
          :error-messages="tweetErrorMessages"
          :image="true"
          :tag-model="TweetTag"
          :value="editingTweet && editingTweet.text"
          class="p-tweetListItem__tweetText--editing"
          data-vv-name="tweetText"
          data-vv-validate-on="blur|input"
          @beginUpload="imageUploading.push(true)"
          @changeAttachments="editingTweet.attachments = $event"
          @endUpload="imageUploading.pop()"
          @input="editingTweet.text = $event"
          @keydown.alt.enter.stop.prevent.capture="save"
          @keydown.ctrl.enter.stop.prevent.capture="save"
          @keydown.shift.enter.stop.prevent.capture="save"
          @keydown.meta.enter.stop.prevent.capture="save"
          @keydown.esc.stop.prevent.capture="cancel"
        />
        <v-row class="ma-0 pa-0">
          <v-btn
            class="mr-2"
            @click="cancel"
          >
            {{ $t('button.cancel') }}
          </v-btn>
          <v-btn
            :disabled="isInvalid"
            color="primary"
            type="submit"
          >
            {{ $t('button.save') }}
          </v-btn>
          <v-spacer />
        </v-row>
      </v-form>
      <v-row
        v-show="!editingTweet"
        class="ma-0 pa-0 p-tweetListItem__tweet"
      >
        <span
          :class="{ 'p-tweetListItem__tweetText--edited': isEdited }"
          class="p-tweetListItem__tweetText flex-grow-1"
          @click.prevent.stop="onClickMessage"
          v-html="message"
        />
        <v-row
          v-if="isOwn"
          align-content="start"
          class="ma-0 flex-grow-0 flex-shrink-0 ml-auto"
        >
          <v-spacer />
          <v-btn
            :disabled="disabled"
            icon
            small
            tile
            @click.capture="edit"
          >
            <v-icon>{{ icons.mdiPencil }}</v-icon>
          </v-btn>
          <v-btn
            :disabled="disabled"
            icon
            small
            tile
            @click.capture="showDeleteDialog"
          >
            <v-icon>{{ icons.mdiDelete }}</v-icon>
          </v-btn>
        </v-row>
      </v-row>
      <ImageList
        :files="tweet.files"
        :is-mine="isOwn"
        size="48"
        @delete="deleteImage"
      />
      <v-row
        align="center"
        class="ma-0 pa-0 flex-wrap mt-2"
      >
        <ReactionChipGroup
          :loading="reactionLoading"
          :value="reactions"
          @click:reaction="selectReaction"
        />
      </v-row>
      <v-row
        v-if="tweet.comments !== undefined && tweet.comments.length >0"
        class="ma-0 pa-0 mt-2"
      >
        <v-col>
          {{ tweet.comments.length }}件の返信があります。
          <a
            v-if="commentable"
            href="#"
            @click.stop.prevent="moveToThread"
          >スレッドを開く</a>
        </v-col>
      </v-row>
    </v-list-item-content>
    <v-dialog
      v-if="isOwn"
      v-model="deleteDialog"
      max-width="260px"
    >
      <v-card>
        <v-card-title
          class="text-h5 grey lighten-2"
          primary-title
        >
          つぶやきを削除する
        </v-card-title>
        <v-card-text>
          <span
            class="p-tweetListItem__tweetText"
            @click="onClickMessage"
            v-html="message"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click.stop="deleteDialog = false"
          >
            キャンセル
          </v-btn>
          <v-btn
            color="error"
            text
            @click.stop="deleteTweet(tweet)"
          >
            削除
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Actions
      :absolute="true"
      :show="!editingTweet && (showActions || showReaction)"
    >
      <ReactionAction
        @hidden="showReaction = false"
        @shown="showReaction = true"
        @select:reaction="selectReaction"
      />
      <CommentAction
        v-if="commentable"
        @click.stop.prevent="moveToThread"
      />
    </Actions>
  </v-list-item>
</template>
<script lang="ts">
import { defaultFeeling } from '@/store/models/Feeling'
import Avatar from '@/components/organisms/Avatar.vue'
import Component, { mixins } from 'vue-class-component'
import Me from '@/store/models/Me'
import { Emit, Prop } from 'vue-property-decorator'
import { Item, Record } from '@vuex-orm/core'
import Tweet from '@/store/models/Tweet'
import Actable from '@/mixins/actable'
import Reactable from '@/mixins/reactable'
import { mdiDelete, mdiPencil } from '@mdi/js'
import _isNil from 'lodash/isNil'
import _get from 'lodash/get'
import { FieldError } from 'vee-validate/types/vee-validate'
import MessageEditor, { MessageEditorValue } from '@/components/organisms/editor/MessageEditor.vue'
import ImageList from '@/components/organisms/ImageList.vue'
import notification from '@/utils/notification'
import File from '@/store/models/File'
import { TweetTag } from '@/store/models/Tag'
import { tagToLink } from '@/utils/markdown'
import notifyResponseError from '@/utils/notifyResponseError'
import CommentAction from '@/components/organisms/actions/CommentAction.vue'

@Component({
  components: {
    ImageList,
    Avatar,
    MessageEditor,
    CommentAction,
  },
})
export default class TweetListItemComponent extends mixins(Actable, Reactable) {
  readonly defaultFeeling = defaultFeeling
  readonly reactableModel = Tweet
  readonly icons = { mdiPencil, mdiDelete }
  readonly TweetTag = TweetTag
  @Prop({ default: {} })
  readonly value!: Record
  @Prop({ default: false })
  readonly disabled!: boolean
  @Prop({ default: false })
  readonly commentable?: boolean
  editingTweet: MessageEditorValue = null
  deleteDialog = false
  imageUploading: boolean[] = []

  get user(): Record {
    return _get(this.tweet, 'user')
  }

  get userName(): string {
    const name = _get(this.user, 'name')
    if (_isNil(_get(this.user, 'deleted_at'))) {
      return name
    }
    return `${name}(利用停止中)`
  }

  get message(): string {
    return tagToLink(this.$marked(this.tweet.text))
  }

  get tweet(): Item<Tweet> {
    return this.item
  }

  get item(): Item<Tweet> {
    if (_isNil(this.value)) return null
    return Tweet.query()
      .with(['user', 'feeling', 'reactions', 'reactions.user', 'comments', 'files'])
      .find(this.value.id)
  }

  get currentUser(): Record {
    return Me.query().first()
  }

  get isOwn(): boolean {
    if (_isNil(this.tweet)) return false
    return this.tweet.user_id === _get(this.currentUser, 'id')
  }

  get isEdited(): boolean {
    return _get(this.tweet, 'edited')
  }

  get isInvalid(): boolean {
    if (_isNil(this.editingTweet)) return true
    if (this.imageUploading.filter((value) => value).length > 0) return true
    if (this.editingTweet.text.replace(/[\s\t\n]+/g, '') === '') return true
    return this.$validator.errors && this.$validator.errors.count() > 0
  }

  get tweetErrorMessages(): FieldError[] {
    if (this.isInvalid) return null
    return this.$validator.errors.collect('tweetText')
  }

  @Emit('startEditing')
  startEditing(): void {
    return void 0
  }

  @Emit('finishEditing')
  finishEditing(): void {
    return void 0
  }

  edit(): void {
    this.editingTweet = {
      text: this.tweet.text,
      attachments: [],
    }
    this.startEditing()
  }

  cancel(): void {
    this.reset()
    this.finishEditing()
  }

  async save(): Promise<void> {
    if (this.isInvalid) return
    if (!(await this.$validator.validateAll())) return
    const tweet: {
      text: string
      attachment_ids?: number[]
    } = {
      text: this.editingTweet.text,
    }
    if (this.editingTweet.attachments.length > 0) {
      tweet['attachment_ids'] = this.editingTweet.attachments.map((file) => file.id)
    }
    const endpoint = ['tweets', this.tweet.id].join('/')
    this.reset()
    this.$http
      .patch(endpoint, tweet)
      .then(({ data }) => {
        Tweet.insertOrUpdate({ data })
        TweetTag.fetchAll({ useCache: false })
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((err: any) => {
        if (err.status === 422) {
          notifyResponseError(err.response.data)
          return
        }
        return Promise.reject(err)
      })
      .finally(() => {
        this.reset()
        this.finishEditing()
      })
  }

  showDeleteDialog(): void {
    this.deleteDialog = true
  }

  async deleteTweet(tweet): Promise<void> {
    if (_isNil(tweet)) return
    this.$http.delete(['tweets', tweet.id].join('/'))
    Tweet.delete(tweet.id)
    this.deleteDialog = false
  }

  reset(): void {
    this.$validator.pause()
    this.$validator.reset().then(() => this.$validator.resume())
    this.showActions = false
    this.showReaction = false
    this.editingTweet = null
  }

  async deleteImage(file): Promise<void> {
    const { data, status } = await this.$http.delete(['tweets', this.tweet.id, 'files', file.id].join('/'))
    if (status !== 200) {
      notification.error(this.$t('message.failed') as string)
      return
    }
    Tweet.insertOrUpdate({ data })
    File.delete(file.id)
    notification.success(this.$t('message.deleted') as string)
  }

  moveToThread(): void {
    this.$router.push({
      name: 'tweets',
      params: {
        id: `${this.tweet.id}`,
      },
    })
  }

  onClickMessage(event: PointerEvent): void {
    const target = event.target as HTMLElement
    const hash = target.getAttribute('data-hashTag')
    if (_isNil(hash)) return
    const name = 'timeline'
    const tab = 'tweet-tag'
    if (this.$route.name === name && this.$route.query.tab === tab && this.$route.hash === hash) {
      return
    }
    this.$router.push({
      name,
      query: {
        tab,
      },
      hash,
    })
  }
}
</script>
<style lang="scss" scoped>
.p-tweetListItem {
  &--editing {
    background-color: #f5f5f5;
  }

  &__userName {
    font-size: .9rem;
  }

  &__tweet {
    width: 100%;

    &Text {
      word-break: break-all;
      word-wrap: break-word;

      &--editing {
        width: 100%;
      }

      &--edited {
        // noinspection CssInvalidPseudoSelector
        :deep(.markdown-body) {
          &::after {
            display: inline-table;
            margin-left: 4px;
            color: #d3d4d3;
            content: '(edited)';
          }

          > :not(ol, ul, pre, blockquote):last-child {
            display: inline;
          }
        }
      }
    }
  }

  &__avatar {
    overflow: visible;
  }
}
</style>
