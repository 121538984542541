import { render, staticRenderFns } from "./UserStatusFilter.vue?vue&type=template&id=2d48676a"
import script from "./UserStatusFilter.vue?vue&type=script&lang=ts"
export * from "./UserStatusFilter.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports