<template>
  <v-dialog
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :value="show"
    content-class="p-send-thanks-dialog overflow-visible"
    max-width="600"
    @input="changeDialog"
  >
    <template #activator="{ on }">
      <slot
        :on="on"
        name="activator"
      />
    </template>
    <template #default="dialog">
      <v-form
        v-model="valid"
        :loading="loading"
        class="fill-height overflow-visible"
        @submit.stop.prevent="give(dialog)"
      >
        <v-card
          class="d-flex flex-column fill-height overflow-visible"
          flat
        >
          <v-card-title
            class="text-h5 grey lighten-2 p-send-thanks-dialog__header"
            primary-title
          >
            感謝をおくる
          </v-card-title>
          <v-card-text
            :class="{
              'p-send-thanks-dialog__body--fullscreen': $vuetify.breakpoint.xsOnly,
            }"
            class="p-send-thanks-dialog__body flex-grow-1 overflow-y-auto"
          >
            <v-row
              align="baseline"
              class="ma-0 pa-0"
            >
              <v-combobox
                v-model="recipient"
                v-validate="'required'"
                :error-messages="errors.collect('recipient')"
                :items="recipients"
                :loading="loadingUsers"
                class="mt-0 mb-2"
                data-vv-name="recipient"
                item-value="id"
                label="宛先"
              />
              さんへ
            </v-row>
            <div class="d-flex flex-column">
              <MessageEditor
                v-model="gift.message.text"
                v-validate="'required|max:500'"
                :attachments="gift.message.attachments"
                :error-messages="$validator.errors.collect('message')"
                class="p-gift__message"
                data-vv-name="message"
                placeholder="感謝のメッセージ"
                @changeAttachments="gift.message.attachments = $event"
              />
            </div>
            <v-combobox
              v-if="categories.length >0"
              :items="categories"
              :loading="loadingCategories"
              :value="gift.categories"
              autocomplete="off"
              chips
              class="mt-0 mb-2"
              clearable
              deletable-chips
              item-text="name"
              label="タグ"
              multiple
              @change="changeCategories"
            />
            <v-row class="ma-0 pa-0">
              <v-col class="pr-4">
                <v-slider
                  v-model="gift.point"
                  :max="_.get(my, 'point')"
                  class="align-end"
                  thumb-label="always"
                >
                  <template #append>
                    <v-text-field
                      v-model="gift.point"
                      :max="_.get(my, 'point')"
                      autocomplete="off"
                      class="text-right mt-0 pt-0"
                      min="0"
                      single-line
                      style="width: 60px"
                      type="number"
                    />
                  </template>
                </v-slider>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn
              :disabled="loading"
              text
              @click.stop="close(dialog)"
            >
              キャンセル
            </v-btn>
            <v-btn
              :disabled="loading || invalid"
              :loading="loading"
              color="primary"
              text
              type="submit"
            >
              おくる
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </template>
  </v-dialog>
</template>
<script lang="ts">
import Vue from 'vue'
import Gift from '@/store/models/Gift'
import _filter from 'lodash/filter'
import Me from '@/store/models/Me'
import { User } from '@/store/models/Person'
import Category from '@/store/models/Category'
import Component from 'vue-class-component'
import { Emit, Prop, Watch } from 'vue-property-decorator'
import { Collection, Item, Record } from '@vuex-orm/core'
import notification from '@/utils/notification'
import _map from 'lodash/map'
import _get from 'lodash/get'
import _isNil from 'lodash/isNil'
import MessageEditor, { MessageEditorValue } from '@/components/organisms/editor/MessageEditor.vue'

type Recipient = User & { text: string }

@Component({
  $_veeValidate: {
    validator: 'new',
  },
  components: {
    MessageEditor,
  },
})
export default class extends Vue {
  @Prop({ default: null })
  readonly value?: boolean
  @Prop({ default: null })
  readonly recipientId?: number
  gift: {
    categories: Collection<Category>
    point: number
    message: MessageEditorValue
  } = {
    categories: [],
    point: 0,
    message: {
      text: '',
      attachments: [],
    },
  }
  valid = false
  loading = false
  loadingUsers = false
  loadingCategories = false
  recipient: Recipient = null
  private _internalValue = false

  get show(): boolean {
    return this.value
  }

  get invalid(): boolean {
    if (this.gift.message.text.replace(/[\s\t\n]+/g, '') === '') return true
    return !this.valid
  }

  get my(): Item<Me> {
    return Me.query().first()
  }

  get recipients(): Recipient[] {
    const users: Collection<User> = User.available()
      .where(({ id }) => {
        return id !== _get(this.my, 'id')
      })
      .get()
    return users.map(user => {
      return {
        ...user,
        text: `${user.name} (${user.email})`,
      } as Recipient
    })
  }

  get categories(): Record[] {
    return Category.query().orderBy('display_order', 'asc').orderBy('id', 'asc').all()
  }

  @Watch('value')
  watchValue(): void {
    this.changeDialog(this.value)
  }

  resetRecipient(): void {
    this.recipient = null
    if (_isNil(this.recipientId)) return
    const user: Item<User> = User.find(this.recipientId)
    if (_isNil(user)) return
    this.recipient = {
      ...user,
      text: `${user.name} (${user.email})`,
    } as Recipient
  }

  changeDialog(value: boolean): void {
    if (this._internalValue === value) return
    if (value) {
      this.resetRecipient()
      this.fetch()
    }
    this.input(value)
  }

  @Emit()
  input(value: boolean): boolean {
    this._internalValue = value
    return value
  }

  fetch(): void {
    this.reset()
    this.loadingUsers = true
    User.fetchAll({ available: true }).finally(() => {
      this.loadingUsers = false
    })
    this.loadingCategories = true
    Category.fetchAll().finally(() => {
      this.loadingCategories = false
    })
  }

  async post(): Promise<void> {
    this.loading = true
    const gift: {
      message: string
      point: number
      categories: Record[]
      'user_id': number
      attachment_ids?: number[]
    } = {
      message: this.gift.message.text,
      point: this.gift.point,
      categories: this.gift.categories,
      'user_id': this.recipient.id,
    }
    if (this.gift.message.attachments.length > 0) {
      gift['attachment_ids'] = this.gift.message.attachments.map((file) => file.id)
    }
    this.$http
      .post(['users', this.recipient.id, 'takes'].join('/'), gift)
      .then(({ data }) => {
        Gift.insertOrUpdate({ data })
        Me.update({
          data: {
            ...this.my,
            point: this.my.point - data.point,
          },
        })
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((err: any) => {
        if (err.status === 422) {
          notification.error(
            _filter(
              _map(err.response.data, (value) => {
                if (typeof value === 'string') {
                  return value
                }
                if (Array.isArray(value)) {
                  return value.join('\n')
                }
                return null
              }),
              (value) => value !== null,
            ).join('\n'),
          )
          this.loading = false
          return
        }
        return Promise.reject(err)
      })
      .finally(() => {
        this.loading = false
      })
  }

  close(dialog): void {
    this.reset()
    dialog.value = false
  }

  async give(dialog): Promise<void> {
    if (!(await this.$validator.validateAll())) {
      return
    }
    await this.post()
    notification.success(this.$t('message.gaveThanks') as string)
    this.close(dialog)
  }

  reset(): void {
    this.$validator.pause()
    this.$validator.reset().then(() => this.$validator.resume())
    this.gift = {
      categories: [],
      point: 0,
      message: {
        text: '',
        attachments: [],
      },
    }
  }

  changeCategories(categories: Collection<Category>): void {
    this.gift.categories = _filter(categories, (category: Item<Category>) => typeof category !== 'string')
  }
}
</script>
<style lang="scss" scoped>
@import 'src/styles/variables';

// noinspection CssInvalidPseudoSelector
:deep(.p-send-thanks-dialog) {
  overflow-x: visible;
}

.text-right {
  // noinspection CssInvalidPseudoSelector
  :deep(input) {
    text-align: right;
  }
}

$dialog-header-height: 32px + 16px + 16px;
$dialog-footer-height: 32px + 8px + 8px + 1px;
$dialog-body-height: calc(90vh - #{$dialog-header-height} - #{$dialog-footer-height});
$dialog-body-fullscreen-height: calc(100vh - #{$dialog-header-height} - #{$dialog-footer-height});
$dialog-body-padding-y: 16px + 16px;
$recipient-height: 74px;
$message-validation-height: 22px + 4px;
$category-height: 64px + 12px + 8px;
$point-height: 62px;
$other-than-message: calc(
  #{$recipient-height} + #{$message-validation-height} + #{$category-height} + #{$point-height}
);

.p-send-thanks-dialog__body {
  max-height: $dialog-body-height;
  overflow-y: auto;

  .p-gift {
    &__message {
      min-height: 200px;
      max-height: calc(#{$dialog-body-height} - #{$dialog-body-padding-y} - #{$other-than-message} - 6px);
    }
  }

  &--fullscreen {
    max-height: $dialog-body-fullscreen-height;

    .p-gift {
      &__message {
        max-height: calc(#{$dialog-body-fullscreen-height} - #{$dialog-body-padding-y} - #{$other-than-message} - 6px);
      }
    }
  }
}

.p-send-thanks-dialog__header {
  + .p-send-thanks-dialog__body.v-card__text {
    padding: 16px;
  }
}
</style>
