<template>
  <v-dialog
    v-model="dialog"
    max-width="1280px"
  >
    <v-form>
      <v-card>
        <v-card-title>利用詳細</v-card-title>
        <v-divider />
        <v-card-text>
          <v-container>
            <v-row class="ma-0 pa-0">
              <v-col
                cols="12"
                md="6"
                sm="6"
              >
                <v-text-field
                  :label="$t('user.keeps')"
                  :value="availablePoints"
                  autocomplete="off"
                  outlined
                  readonly
                  type="text"
                />
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0">
              <v-col
                cols="12"
                md="6"
                sm="6"
              >
                <v-text-field
                  v-model="point"
                  v-validate="rules.point"
                  :error-messages="errors.collect('exchange.point')"
                  :label="$t('exchange.point')"
                  :readonly="readOnly || willDelete"
                  autocomplete="off"
                  data-vv-name="exchange.point"
                  required
                />
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0">
              <v-col cols="12">
                <v-textarea
                  v-model="memo"
                  v-validate="rules.memo"
                  :error-messages="errors.collect('exchange.memo')"
                  :label="$t('exchange.memo')"
                  :readonly="readOnly || willDelete"
                  data-vv-name="exchange.memo"
                  required
                />
              </v-col>
            </v-row>
            <v-row
              v-if="!isNew && !readOnly"
              class="ma-0 pa-0"
            >
              <v-col cols="12">
                <v-checkbox
                  v-model="willDelete"
                  hide-details
                  label="この利用履歴を削除します。"
                  @change="onChangeWillDelete"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <template v-if="isNew">
            <v-btn
              :disabled="loading"
              :loading="loading"
              color="primary"
              type="button"
              @click.stop.prevent="onClickRegister"
            >
              {{ $t('button.register') }}
            </v-btn>
          </template>
          <template v-else-if="!readOnly">
            <v-btn
              :disabled="loading || readOnly || !willDelete"
              :loading="loading"
              color="error"
              type="button"
              @click.stop.prevent="onClickDelete"
            >
              {{ $t('button.delete') }}
            </v-btn>
            <v-btn
              :disabled="loading || readOnly"
              :loading="loading"
              color="primary"
              type="button"
              @click.stop.prevent="onClickSave"
            >
              {{ $t('button.save') }}
            </v-btn>
          </template>
          <v-btn
            :disabled="loading"
            :loading="loading"
            @click.stop.prevent="onClickCancel"
          >
            {{ $t('button.cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Emit, Prop, Watch } from 'vue-property-decorator'
import Exchange from '@/store/models/Exchange'
import { Item } from '@vuex-orm/core'
import { User } from '@/store/models/Person'

@Component({
  $_veeValidate: {
    validator: 'new',
  },
})
export default class ExchangeDetailDialog extends Vue {
  @Prop({ default: false })
  readonly value?: boolean
  @Prop({ default: undefined })
  readonly exchange?: Item<Exchange>
  @Prop({ default: false })
  readonly readOnly?: boolean
  @Prop({ default: null })
  readonly userId!: number

  loading = false
  willDelete = false
  point = 0
  memo = ''
  isNew = true

  get dialog(): boolean {
    return this.value
  }

  set dialog(value: boolean) {
    if (this.value !== value) {
      this.input(value)
    }
  }

  get rules(): {
    point: string
    memo: string
  } {
    return {
      point: `required|min_value:0|max_value:${this.availablePoints}`,
      memo: 'required',
    }
  }

  get availablePoints() {
    return (this.user?.keeps || 0) + (this.exchange?.point || 0)
  }

  get user(): Item<User> {
    return User.find(this.userId)
  }

  @Emit()
  input(value: boolean): boolean {
    return value
  }

  @Watch('value')
  watchValue() {
    if (!this.value) {
      return
    }
    this.willDelete = false
    if (this.readOnly) {
      this.$validator.pause()
    } else {
      this.$validator.resume()
    }
    this.$validator.reset()
  }

  @Watch('userId')
  watchUserId() {
    User.fetch(this.userId)
  }

  @Watch('exchange')
  watchExchange() {
    this.isNew = !this.exchange
    this.point = this.exchange?.point || 0
    this.memo = this.exchange?.memo || ''
  }

  onChangeWillDelete() {
    if (!this.willDelete) {
      return
    }
    this.point = this.exchange?.point || 0
    this.memo = this.exchange?.memo || ''
  }

  onClickCancel(): void {
    this.dialog = false
  }

  async onClickDelete(): Promise<void> {
    this.loading = true
    if (!(await this.$validator.validateAll())) {
      this.loading = false
      return
    }
    try {
      const { data } = await this.$http.delete(`exchanges/${this.exchange.id}`)
      await Exchange.insertOrUpdate({ data })
      User.fetch(this.user.id)
    } finally {
      this.loading = false
      this.dialog = false
    }
  }

  async onClickSave(): Promise<void> {
    this.loading = true
    if (!(await this.$validator.validateAll())) {
      this.loading = false
      return
    }
    try {
      const { data } = await this.$http.patch(`exchanges/${this.exchange.id}`, {
        memo: this.memo,
        point: this.point,
      })
      await Exchange.insertOrUpdate({ data })
      User.fetch(this.user.id)
    } finally {
      this.loading = false
      this.dialog = false
    }
  }

  async onClickRegister(): Promise<void> {
    this.loading = true
    if (!(await this.$validator.validateAll())) {
      this.loading = false
      return
    }
    try {
      const { data } = await this.$http.post(`users/${this.user.id}/exchanges`, {
        memo: this.memo,
        point: this.point,
      })
      await Exchange.insertOrUpdate({ data })
      User.fetch(this.user.id)
    } finally {
      this.loading = false
      this.dialog = false
    }
  }
}
</script>
