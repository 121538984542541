<template>
  <v-data-table
    :footer-props="{
      'items-per-page-options': [25, 50, 75, 100, -1],
    }"
    :headers="headers"
    :item-class="itemClass"
    :items="items"
    :loading="loading"
    :options="options"
    :server-items-length="serverItemsLength"
    @update:options="updateOptions"
    @click:row="clickRow"
  >
    <template #top>
      <slot name="top" />
    </template>
    <template
      v-if="itemSlots.length === 0"
      #item="{ item }"
    >
      <slot
        :item="item"
        name="item"
      />
    </template>
    <template
      v-for="itemSlot in itemSlots"
      #[itemSlot]="{ item }"
    >
      <slot
        :item="item"
        :name="itemSlot"
      />
    </template>
  </v-data-table>
</template>
<script lang="ts">
import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import { DataOptions, DataTableHeader } from 'vuetify'
import Component from 'vue-class-component'
import Model from '@/store/models/Model'
import { Collection } from '@vuex-orm/core'

@Component
export default class MyDataTable<T extends Model> extends Vue {
  @Prop({ default: [] })
  readonly headers?: DataTableHeader[]
  @Prop({ default: [] })
  readonly items: Collection<T>
  @Prop({ default: {} })
  readonly options?: Partial<DataOptions>
  @Prop({ default: false })
  readonly loading?: boolean
  @Prop({ default: null })
  readonly itemClass?: string | ((...args: any[]) => string)
  @Prop({ default: -1 })
  readonly serverItemsLength?: number

  get itemSlots() {
    return Object.keys(this.$scopedSlots)
      .filter(key => /^item\./.test(key))
  }

  updateOptions(...args: any[]): void {
    this.$emit('update:options', ...args)
  }

  clickRow(...args: any[]): void {
    this.$emit('click:row', ...args)
  }
}
</script>
