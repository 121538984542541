import { render, staticRenderFns } from "./PanelBase.vue?vue&type=template&id=154c4b73&scoped=true"
import script from "./PanelBase.vue?vue&type=script&lang=ts"
export * from "./PanelBase.vue?vue&type=script&lang=ts"
import style0 from "./PanelBase.vue?vue&type=style&index=0&id=154c4b73&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "154c4b73",
  null
  
)

export default component.exports